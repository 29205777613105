import { connect } from 'react-redux';
import TableToolbar from './TableToolbar';
import { rosterOperations } from '../../modules/roster';

const mapDispatchToProps = dispatch => ({
  unlockAllCharacters: () => dispatch(rosterOperations.unlockAllCharacters()),
});

export default connect(
  null,
  mapDispatchToProps,
)(TableToolbar);
