import React from 'react';
import { Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TableToolbarContainer from './TableToolbarContainer';
import CharacterListContainer from './CharacterListContainer';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

const RosterPage = () => {
  const classes = useStyles();
  return (
    <Grid item xs={12} md={12}>
      <Paper className={classes.paper}>
        <TableToolbarContainer title="Roster" />
        <CharacterListContainer />
      </Paper>
    </Grid>
  );
};

export default RosterPage;
