import React from 'react';
import { LinearProgress, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  paper: {
    width: '100%',
  },
}));

const ProgressIndicator = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <LinearProgress />
    </Paper>
  );
};

export default ProgressIndicator;
