import React from 'react';
import PropTypes from 'prop-types';
import { GridListTileBar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CharacterTile from '../../components/CharacterTile';
import ReactGridLayout from '../../components/ReactGridLayout';

const gridRowHeights = { lg: 96, md: 96, sm: 96, xs: 64, xxs: 64 };

const useStyles = makeStyles(() => ({
  tileBar: {
    height: '2em',
    fontSize: '0.5em',
    bottom: '0px',
    paddingBottom: '0.25em',
  },
}));

const getImageLayout = floorLayout => {
  const layout = [];
  if (floorLayout === null || floorLayout === undefined) {
    return { lg: layout, md: layout };
  }
  if (floorLayout.boss !== null) {
    layout.push({
      i: 'boss',
      x: 0,
      y: 0,
      h: 1,
      w: 1,
      image: floorLayout.boss,
      static: true,
    });
  }
  if (floorLayout.mob1 !== null) {
    layout.push({
      i: 'mob1',
      x: 1,
      y: 0,
      h: 1,
      w: 1,
      image: floorLayout.mob1,
      static: true,
    });
  }
  if (floorLayout.mob2 !== null) {
    layout.push({
      i: 'mob2',
      x: 2,
      y: 0,
      h: 1,
      w: 1,
      image: floorLayout.mob2,
      static: true,
    });
  }
  if (floorLayout.mob3 !== null) {
    layout.push({
      i: 'mob3',
      x: 3,
      y: 0,
      h: 1,
      w: 1,
      image: floorLayout.mob3,
      static: true,
    });
  }
  if (floorLayout.mob4 !== null) {
    layout.push({
      i: 'mob4',
      x: 4,
      y: 0,
      h: 1,
      w: 1,
      image: floorLayout.mob4,
      static: true,
    });
  }
  if (floorLayout.mob5 !== null) {
    layout.push({
      i: 'mob5',
      x: 5,
      y: 0,
      h: 1,
      w: 1,
      image: floorLayout.mob5,
      static: true,
    });
  }
  return { lg: layout, md: layout, sm: layout };
};

const StageEnemiesGridLayout = ({ floorLayout }) => {
  const classes = useStyles();
  const gridLayout = getImageLayout(floorLayout);
  const [state, setState] = React.useState({ rowHeight: 96 });
  return (
    <ReactGridLayout
      layouts={gridLayout}
      breakpoints={{ lg: 650, md: 500, sm: 450, xs: 200, xxs: 0 }}
      cols={{ lg: 7, md: 6, sm: 7, xs: 7, xxs: 10 }}
      containerPadding={[0, 0]}
      margin={[1, 1]}
      onBreakpointChange={breakpoint => {
        setState({ ...state, rowHeight: gridRowHeights[breakpoint] });
      }}
      rowHeight={state.rowHeight}
    >
      {gridLayout.lg.map(tileInfo => {
        return (
          <CharacterTile
            key={tileInfo.i}
            imageSource={`/images/${tileInfo.image}`}
          >
            {tileInfo.i === 'boss' && (
              <GridListTileBar title="Boss" className={classes.tileBar} />
            )}
          </CharacterTile>
        );
      })}
    </ReactGridLayout>
  );
};

StageEnemiesGridLayout.defaultProps = {
  floorLayout: null,
};

StageEnemiesGridLayout.propTypes = {
  floorLayout: PropTypes.shape({
    id: PropTypes.number,
    modeId: PropTypes.number,
    floorTypeTip: PropTypes.string,
    floorTip: PropTypes.string,
    boss: PropTypes.string,
    mob1: PropTypes.string,
    mob2: PropTypes.string,
    mob3: PropTypes.string,
    mob4: PropTypes.string,
    mob5: PropTypes.string,
  }),
};

export default StageEnemiesGridLayout;
