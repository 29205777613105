import { rosterSelectors } from '../roster';

const getCurrentFloor = state => {
  return state.shadowland.currentFloor;
};

const getShadowlandFloor = state => {
  return state.shadowland.floor[getCurrentFloor(state)];
};

const getCharacterSelection = state => {
  const shadowlandFloor = getShadowlandFloor(state);
  return shadowlandFloor !== undefined ? shadowlandFloor.characters || [] : [];
};

const getFloorNotes = state => {
  const shadowlandFloor = getShadowlandFloor(state);
  return shadowlandFloor !== undefined ? shadowlandFloor.notes || '' : '';
};

export const isTypeAdvantage = (character, typeAdvantage) => {
  if (character.type === typeAdvantage) {
    return 1;
  }
  return -1;
};

export const getStageAdvantage = stage => {
  if (stage.floorTypeTip === null) {
    return 0;
  }
  if (stage.floorTypeTip.includes('Blast-type')) {
    return 3;
  }
  if (stage.floorTypeTip.includes('Combat-type')) {
    return 1;
  }
  if (stage.floorTypeTip.includes('Speed-type')) {
    return 2;
  }
  if (stage.floorTypeTip.includes('Universal-type')) {
    return 4;
  }
  return 0;
};

const getCharactersOrdered = state => {
  if (state.entities.floorLayouts === undefined) {
    return [];
  }

  const availableCharacters = rosterSelectors.getAvailableCharacters(state);
  return availableCharacters;
};

const getUsedCharacters = state => {
  return state.shadowland.usedCharacters;
};

export default {
  getCurrentFloor,
  getShadowlandFloor,
  getCharacterSelection,
  getFloorNotes,
  getCharactersOrdered,
  getUsedCharacters,
};
