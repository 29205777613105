import React, { useState } from 'react';
import { Paper, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));

const CallbackPage = ({
  isLoggedIn,
  isProfileLoaded,
  hasProfile,
  getProfile,
}) => {
  const [checkProfile, setCheckProfile] = useState(false);
  const classes = useStyles();

  if (isLoggedIn && !checkProfile) {
    getProfile();
    setCheckProfile(true);
  }

  if (isProfileLoaded && !hasProfile) {
    return <Redirect to="/profile" />;
  }
  if (isProfileLoaded && hasProfile) {
    return <Redirect to="/shadowland" />;
  }

  return (
    <Grid item xs={12} md={12}>
      <Paper className={classes.paper}>
        <LinearProgress />
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open
          autoHideDuration={6000}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<Typography id="message-id">Logging In</Typography>}
        />
      </Paper>
    </Grid>
  );
};

CallbackPage.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  isProfileLoaded: PropTypes.bool.isRequired,
  hasProfile: PropTypes.bool.isRequired,
  getProfile: PropTypes.func.isRequired,
};

export default CallbackPage;
