import React from 'react';
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import GroupIcon from '@material-ui/icons/Group';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ManageIcon from '@material-ui/icons/Build';
import { NavLink } from 'react-router-dom';
import ShadowlandIcon from './ShadowlandIcon';

export const drawerWidth = 180;

const useStyles = makeStyles(theme => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.dark,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: theme.palette.primary.dark,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  drawerText: {
    color: theme.palette.secondary.main,
  },
  navLink: {
    color: 'inherit',
  },
}));

const apps = [
  {
    name: 'roster',
    title: 'Roster',
    tooltip: 'Configure Roster',
  },
  {
    name: 'shadowland',
    title: 'Shadowland',
    tooltip: 'Shadowland',
  },
  {
    name: 'manage',
    title: 'Manage',
    tooltip: 'Manage builds',
  },
];

const getIcon = appName => {
  switch (appName) {
    case 'roster':
      return <GroupIcon color="secondary" />;
    case 'shadowland':
      return <ShadowlandIcon color="secondary" />;
    case 'manage':
      return <ManageIcon color="secondary" />;
    default:
      return <div />;
  }
};

const AppDrawer = ({ isOpen, setApp, closeAppDrawer }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isOpen,
        [classes.drawerClose]: !isOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: isOpen,
          [classes.drawerClose]: !isOpen,
        }),
      }}
      open={isOpen}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={closeAppDrawer}>
          {theme.rtl ? (
            <ChevronRightIcon color="secondary" />
          ) : (
            <ChevronLeftIcon color="secondary" />
          )}
        </IconButton>
      </div>
      <Divider />
      <List>
        {apps.map(app => {
          const Icon = getIcon(app.name);
          return (
            <NavLink to={app.name} key={app.name} className={classes.navLink}>
              <ListItem button onClick={() => setApp(app.name)}>
                <Tooltip title={app.tooltip}>
                  <ListItemIcon>{Icon}</ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary={app.title}
                  primaryTypographyProps={{ color: 'secondary' }}
                />
              </ListItem>
            </NavLink>
          );
        })}
      </List>
    </Drawer>
  );
};

AppDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setApp: PropTypes.func.isRequired,
  closeAppDrawer: PropTypes.func.isRequired,
};

export default AppDrawer;
