import { IconButton, Menu, MenuItem, Avatar } from '@material-ui/core';
import React from 'react';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useAuth0 } from '../react-auth0-wrapper';

const useStyles = makeStyles({
  avatar: {
    margin: 0,
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
});

const UserProfile = () => {
  const classes = useStyles();
  const { loading, user, logout } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  function handleMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <IconButton
        aria-label="Account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
        size="small"
      >
        {loading && <AccountCircle />}
        {user && <Avatar src={user.picture} className={classes.avatar} />}
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        <Link
          to="/profile"
          style={{ textDecoration: 'none', display: 'block' }}
        >
          <MenuItem onClick={handleClose}>Profile</MenuItem>
        </Link>
        <MenuItem onClick={() => logout()}>Log out</MenuItem>
      </Menu>
    </>
  );
};

export default UserProfile;
