const getAvailableCharacters = state => {
  const keys = Object.keys(state.roster);
  return keys
    .filter(r => state.roster[r].owned)
    .map(k => {
      return parseInt(k, 10);
    });
};

export default {
  getAvailableCharacters,
};
