import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Chip } from '@material-ui/core';
import AppDrawerContainer from './AppDrawerContainer';
import { drawerWidth } from './AppDrawer';
import UserNavBarContainer from './UserNavBarContainer';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  hide: {
    display: 'none',
  },
  title: {
    paddingRight: '0.75em',
  },
  grow: {
    flexGrow: 1,
  },
}));

const AppNavBar = ({ title, isOpen, openAppDrawer }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <AppBar
        position="fixed"
        color="primary"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: isOpen,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Menu"
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: isOpen,
            })}
            onClick={openAppDrawer}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.title}>
            {title}
          </Typography>
          <Chip variant="outlined" label="BETA" color="secondary" />
          <div className={classes.grow} />
          <UserNavBarContainer />
        </Toolbar>
      </AppBar>
      <AppDrawerContainer />
    </React.Fragment>
  );
};

AppNavBar.defaultProps = {
  title: 'Shadowland Teams',
};

AppNavBar.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  openAppDrawer: PropTypes.func.isRequired,
};

export default AppNavBar;
