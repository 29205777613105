import React from 'react';
import { IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const characterTypes = {
  1: 'combat.png',
  2: 'speed.png',
  3: 'blast.png',
  4: 'universal.png',
};

const useStyles = makeStyles(() => ({
  typeIcon: {
    height: '32px',
    margin: 0,
  },
  button: {
    paddingLeft: '0.25em',
    marginBottom: '0.75em',
  },
}));

const CharacterTypeIconButton = ({ characterType, altText }) => {
  const classes = useStyles();
  return (
    <IconButton className={classes.button}>
      <img
        src={`/images/${characterTypes[characterType]}`}
        alt={altText}
        className={classes.typeIcon}
      />
    </IconButton>
  );
};

CharacterTypeIconButton.defaultProps = {
  altText: '',
};

CharacterTypeIconButton.propTypes = {
  characterType: PropTypes.number.isRequired,
  altText: PropTypes.string,
};

export default CharacterTypeIconButton;
