import { connect } from 'react-redux';
import AvailableRosterCard from './AvailableRosterCard';
import {
  shadowlandOperations,
  shadowlandSelectors,
} from '../../modules/shadowland';
import { characterSelectors } from '../../modules/entities/characters';

const mapStateToProps = state => ({
  roster: shadowlandSelectors.getCharactersOrdered(state),
  characterList: characterSelectors.characterListSelector(state),
  currentFloor: shadowlandSelectors.getCurrentFloor(state),
  usedCharacters: shadowlandSelectors.getUsedCharacters(state),
});

const mapDispatchToProps = dispatch => ({
  assignCharacter: (floor, characterId) =>
    dispatch(shadowlandOperations.assignCharacter(floor, characterId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AvailableRosterCard);
