const getUser = state => {
  return state.auth.user;
};

const isLoggedIn = state => {
  return state.auth.isLoggedIn === true;
};

const getApiToken = state => {
  return state.auth.apiToken;
};

const isProfileLoaded = state => {
  return state.auth.profileLoaded === true;
};

const hasProfile = state => {
  return state.auth.profileLoaded && Object.keys(state.auth.profile).length > 0;
};

const getProfile = state => {
  return state.auth.profile;
};

export default {
  getUser,
  isLoggedIn,
  getApiToken,
  hasProfile,
  isProfileLoaded,
  getProfile,
};
