import { takeEvery, call, put } from 'redux-saga/effects';
import actions from './actions';
import types from './types';

function getData() {
  return fetch(process.env.REACT_APP_FLOOR_API).then(response =>
    response.json(),
  );
}

function* fetchFloors() {
  try {
    const payload = yield call(getData);
    yield put(actions.requestFloorsSuccess(payload));
  } catch (e) {
    yield put(actions.requestFloorsError(e));
  }
}

function* watchRequestFloorsBegin() {
  yield takeEvery(types.REQUEST_FLOORS_BEGIN, fetchFloors);
}

export default watchRequestFloorsBegin;
