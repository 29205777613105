import { connect } from 'react-redux';
import CharacterList from './CharacterList';
import { rosterOperations } from '../../modules/roster';
import { characterSelectors } from '../../modules/entities/characters';

const mapStateToProps = state => ({
  characters: characterSelectors.characterListSelector(state),
  roster: state.roster,
});

const mapDispatchToProps = dispatch => ({
  setCharacterUnlocked: characterId =>
    dispatch(rosterOperations.setCharacterUnlocked(characterId)),
  setCharacterLocked: characterId =>
    dispatch(rosterOperations.setCharacterLocked(characterId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CharacterList);
