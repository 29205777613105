import types from './types';
import arrayToObject from '../../../util/stateHelper';
/* State shape
{
  id: {
     "id": number,
        "floor": number,
        "floorLayoutId": number,
        "created_at": datetime,
        "updated_at": datetime
  }.
}
*/

const initialFloorsState = {
  byId: {},
  allIds: [],
};

const floorsReducer = (state = initialFloorsState, action) => {
  switch (action.type) {
    case types.REQUEST_FLOORS_SUCCESS: {
      const toInitialize = arrayToObject(action.payload, 'id');
      return {
        ...state,
        byId: toInitialize,
        allIds: action.payload.map(f => f.id),
      };
    }
    case types.REQUEST_FLOORS_ERROR:
    default:
      return state;
  }
};

export default floorsReducer;
