import types from './types';
import { manageTypes } from '../manage';
/* State shape
{
  current: string
}
*/

const initialAppState = { current: 'roster' };

const appReducer = (state = initialAppState, action) => {
  switch (action.type) {
    case types.SET_APP:
      return {
        ...state,
        current: action.app,
      };
    case manageTypes.LOAD_BUILD:
      return {
        ...state,
        current: 'shadowland',
      };
    default:
      return state;
  }
};

export default appReducer;
