import React from 'react';
import {
  Card,
  CardHeader,
  CardMedia,
  GridListTileBar,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CharacterTile from '../../components/CharacterTile';
import ReactGridLayout from '../../components/ReactGridLayout';

const useStyles = makeStyles(() => ({
  rightAligned: {
    marginLeft: 'auto',
    display: 'flex',
  },
  avatar: {
    backgroundColor: '#263238',
  },
  roster: {
    height: '30vh',
    overflowY: 'scroll',
    overflowX: 'hidden',
    background: 'rgba(30,40,45,1)',
  },
  tileBar: {
    height: '4em',
    fontSize: '0.5em',
    top: '45%',
  },
}));

const generateLayout = roster => {
  return {
    lg: roster.map((r, index) => {
      return {
        i: `${r}`,
        x: index % 10,
        y: Math.floor(index / 10),
        w: 1,
        h: 1,
        static: true,
        maxW: 128,
      };
    }),
    md: roster.map((r, index) => {
      return {
        i: `${r}`,
        x: index % 8,
        y: Math.floor(index / 8),
        w: 1,
        h: 1,
        static: true,
        maxW: 128,
      };
    }),
    sm: roster.map((r, index) => {
      return {
        i: `${r}`,
        x: index % 6,
        y: Math.floor(index / 6),
        w: 1,
        h: 1,
        static: true,
        maxW: 128,
      };
    }),
    xs: roster.map((r, index) => {
      return {
        i: `${r}`,
        x: index % 4,
        y: Math.floor(index / 4),
        w: 1,
        h: 1,
        static: true,
        maxW: 128,
      };
    }),
  };
};

const AvailableRosterCard = ({
  roster,
  characterList,
  currentFloor,
  assignCharacter,
  usedCharacters,
}) => {
  const classes = useStyles();
  const layout = generateLayout(roster);
  return (
    <Card>
      <CardHeader
        title="Available Roster"
        titleTypographyProps={{ variant: 'h6' }}
        action={<div className={classes.rightAligned} />}
      />
      <CardMedia title="Characters" image="null" className={classes.roster}>
        <ReactGridLayout
          layouts={layout}
          containerPadding={[0, 0]}
          margin={[0, 0]}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 10, md: 8, sm: 6, xs: 4, xxs: 2 }}
        >
          {roster.map(r => {
            const assigned = usedCharacters.indexOf(r) >= 0;
            return (
              <CharacterTile
                key={`${r}`}
                onClick={() => assignCharacter(currentFloor, r)}
                imageSource={`/images/${characterList[r - 1].fileName}`}
                locked={assigned}
              >
                {assigned && (
                  <GridListTileBar
                    title="Assigned"
                    className={classes.tileBar}
                  />
                )}
              </CharacterTile>
            );
          })}
        </ReactGridLayout>
      </CardMedia>
    </Card>
  );
};

AvailableRosterCard.propTypes = {
  roster: PropTypes.arrayOf(PropTypes.number).isRequired,
  characterList: PropTypes.arrayOf(
    PropTypes.shape({
      fileName: PropTypes.string,
    }),
  ).isRequired,
  currentFloor: PropTypes.number.isRequired,
  usedCharacters: PropTypes.arrayOf(PropTypes.number).isRequired,
  assignCharacter: PropTypes.func.isRequired,
};

export default AvailableRosterCard;
