import { takeEvery, call, put } from 'redux-saga/effects';
import actions from './actions';
import types from './types';

function getData() {
  return fetch(process.env.REACT_APP_FLOORLAYOUT_API).then(response =>
    response.json(),
  );
}

function* fetchFloorLayouts() {
  try {
    const payload = yield call(getData);
    yield put(actions.requestFloorLayoutsSuccess(payload));
  } catch (e) {
    yield put(actions.requestFloorLayoutsError(e));
  }
}

function* watchRequestFloorLayoutsBegin() {
  yield takeEvery(types.REQUEST_FLOOR_LAYOUTS_BEGIN, fetchFloorLayouts);
}

export default watchRequestFloorLayoutsBegin;
