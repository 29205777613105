import types from './types';

/* State shape
{
    isOpen: boolean
}
*/

const initialDrawerState = {
  isOpen: false,
};

const drawerReducer = (state = initialDrawerState, action) => {
  switch (action.type) {
    case types.OPEN_APP_DRAWER:
      return { ...state, isOpen: true };
    case types.CLOSE_APP_DRAWER:
      return { ...state, isOpen: false };
    default:
      return state;
  }
};

export default drawerReducer;
