import { characterOperations } from './modules/entities/characters';
import { floorOperations } from './modules/entities/floors';
import { floorLayoutOperations } from './modules/entities/floorLayouts';

const initialize = store => {
  const { entities } = store.getState();
  if (!entities.characters || entities.characters.allIds.length <= 0) {
    store.dispatch(characterOperations.requestCharactersBegin());
  }

  if (!entities.floors || entities.floors.allIds.length <= 0) {
    store.dispatch(floorOperations.requestFloorsBegin());
  }

  if (!entities.floorLayouts || entities.floorLayouts.allIds.length <= 0) {
    store.dispatch(floorLayoutOperations.requestFloorLayoutsBegin());
  }
};

export default initialize;
