import uuid from 'uuid';
import types from './types';

const saveBuild = (name, description, build) => {
  return {
    type: types.SAVE_BUILD,
    id: uuid.v4(),
    name,
    description,
    build,
    createdAt: Date.now(),
    updatedAt: Date.now(),
  };
};

const deleteBuild = id => {
  return {
    type: types.DELETE_BUILD,
    id,
  };
};

const loadBuild = build => {
  return {
    type: types.LOAD_BUILD,
    build,
  };
};

export default {
  saveBuild,
  deleteBuild,
  loadBuild,
};
