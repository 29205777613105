import actions from './actions';

const {
  prevFloor,
  nextFloor,
  setFloor,
  setStage,
  assignCharacter,
  unassignCharacter,
  setNotes,
} = actions;

export default {
  prevFloor,
  nextFloor,
  setFloor,
  setStage,
  assignCharacter,
  unassignCharacter,
  setNotes,
};
