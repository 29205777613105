import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import {
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import FilterListIcon from '@material-ui/icons/FilterList';
import MoreIcon from '@material-ui/icons/MoreVert';

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  grow: {
    flexGrow: 1,
  },
  actions: {
    color: theme.palette.text.secondary,
    display: 'flex',
  },
  floatIcon: {
    float: 'left',
  },
  title: {
    flex: '0 0 auto',
  },
}));

const TableToolbar = ({ title, unlockAllCharacters }) => {
  const classes = useToolbarStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);

    unlockAllCharacters();
  }

  return (
    <Toolbar className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6" id="tableTitle">
          {title}
        </Typography>
      </div>
      <div className={classes.grow} />
      <div className={classes.actions}>
        <Tooltip title="Filter list">
          <IconButton aria-label="Filter list" disabled>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="More">
          <IconButton
            aria-label="More"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreIcon />
          </IconButton>
        </Tooltip>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>Unlock All</MenuItem>
          <MenuItem onClick={handleClose} disabled>
            Max Tier All
          </MenuItem>
        </Menu>
      </div>
    </Toolbar>
  );
};

TableToolbar.defaultProps = {
  title: 'Roster',
};

TableToolbar.propTypes = {
  title: PropTypes.string,
  unlockAllCharacters: PropTypes.func.isRequired,
};

export default TableToolbar;
