import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      light: '#5c5c5e',
      main: '#282c34',
      dark: '#212121',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffffb0',
      main: '#ffffff',
      dark: '#2196f3',
      contrastText: '#000',
    },
    background: {
      default: '#5c6370',
    },
  },
});

export default theme;
