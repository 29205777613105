import { combineReducers } from 'redux';
import shadowland from './shadowland';
import roster from './roster';
import uiReducers from './ui';
import entities from './entities';
import app from './app';
import manage from './manage';
import auth from './auth';

export default combineReducers({
  app,
  shadowland,
  roster,
  ui: uiReducers,
  entities,
  manage,
  auth,
});
