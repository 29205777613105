import actions from './actions';

const {
  requestProfileBegin,
  setApiToken,
  setLoggedIn,
  saveProfileBegin,
} = actions;

export default {
  requestProfileBegin,
  setApiToken,
  setLoggedIn,
  saveProfileBegin,
};
