import { connect } from 'react-redux';
import AppDrawer from './AppDrawer';
import { drawerOperations } from '../modules/ui/drawer';
import { appOperations } from '../modules/app';

const mapStateToProps = state => ({
  isOpen: state.ui.drawer.isOpen,
});

const mapDispatchToProps = dispatch => ({
  setApp: app => dispatch(appOperations.setApp(app)),
  closeAppDrawer: () => dispatch(drawerOperations.closeAppDrawer()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppDrawer);
