import types from './types';

const openAppDrawer = () => {
  return {
    type: types.OPEN_APP_DRAWER,
  };
};

const closeAppDrawer = () => {
  return {
    type: types.CLOSE_APP_DRAWER,
  };
};

export default {
  openAppDrawer,
  closeAppDrawer,
};
