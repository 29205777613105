import React from 'react';
import { Grid } from '@material-ui/core';
import CharacterSelectionCardContainer from './CharacterSelectionCardContainer';
import FloorCardContainer from './FloorCardContainer';
import AvailableRosterCardContainer from './AvailableRosterCardContainer';

const Shadowland = () => {
  return (
    <React.Fragment>
      <Grid item xs={12} md={6}>
        <FloorCardContainer />
      </Grid>
      <Grid item xs={12} md={6}>
        <CharacterSelectionCardContainer />
      </Grid>
      <Grid item xs={12} md={12}>
        <AvailableRosterCardContainer />
      </Grid>
    </React.Fragment>
  );
};

export default Shadowland;
