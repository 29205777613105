import types from './types';
/* State shape
{
  isLoggedIn: bool,
  apiToken: string,
}
*/

const initialAuthState = {
  isLoggedIn: false,
  apiToken: null,
  profileLoaded: false,
  profile: { alias: '' },
};

const authReducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case types.REQUEST_PROFILE_SUCCESS: {
      return {
        ...state,
        profileLoaded: true,
        profile: action.payload || null,
      };
    }
    case types.REQUEST_PROFILE_BEGIN:
    case types.REQUEST_PROFILE_ERROR: {
      return {
        ...state,
        profileLoaded: false,
      };
    }
    case types.SET_LOGGED_IN: {
      return {
        ...state,
        isLoggedIn: action.isLoggedIn,
      };
    }
    case types.SET_API_TOKEN:
      return {
        ...state,
        apiToken: action.token,
      };
    case types.SAVE_PROFILE_SUCCESS: {
      return {
        ...state,
        profileLoaded: true,
        profile: action.payload,
      };
    }
    default:
      return state;
  }
};

export default authReducer;
