import types from './types';

const unlockAllCharacters = () => {
  return {
    type: types.UNLOCK_ALL,
  };
};

const setCharacterUnlocked = characterId => {
  return {
    type: types.SET_CHARACTER_UNLOCKED,
    characterId,
  };
};

const setCharacterLocked = characterId => {
  return {
    type: types.SET_CHARACTER_LOCKED,
    characterId,
  };
};

const getRoster = () => {
  return {
    type: types.GET_ROSTER,
  };
};

const setTier = (characterId, tier) => {
  return {
    type: types.SET_TIER,
    characterId,
    tier,
  };
};

export default {
  setCharacterLocked,
  setCharacterUnlocked,
  getRoster,
  setTier,
  unlockAllCharacters,
};
