import React from 'react';
import { Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  root: {
    width: '100%',
  },
  scrollFix: {
    width: '1px',
  },
}));

const StyledTabs = props => {
  const classes = useStyles();
  return (
    <Tabs
      {...props}
      classes={{
        indicator: classes.indicator,
        root: classes.root,
        scrollable: classes.scrollFix,
      }}
      TabIndicatorProps={{ children: <div /> }}
    />
  );
};

export default StyledTabs;
