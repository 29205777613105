import { connect } from 'react-redux';
import CurrentBuildCard from './CurrentBuildCard';
import { manageOperations } from '../../modules/manage';

const mapStateToProps = state => ({
  currentBuild: state.shadowland,
});

const mapDispatchToProps = dispatch => ({
  saveBuild: (name, description, build) =>
    dispatch(manageOperations.saveBuild(name, description, build)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CurrentBuildCard);
