import { connect } from 'react-redux';
import BuildsTable from './BuildsTable';
import { manageSelectors, manageOperations } from '../../modules/manage';

const mapStateToProps = state => ({
  builds: manageSelectors.getBuilds(state),
});

const mapDispatchToProps = dispatch => ({
  deleteBuild: id => dispatch(manageOperations.deleteBuild(id)),
  loadBuild: build => dispatch(manageOperations.loadBuild(build)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BuildsTable);
