import { Button } from '@material-ui/core';
import React from 'react';
import { useAuth0 } from '../react-auth0-wrapper';
import UserProfile from './UserProfile';

const UserNavBar = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  return (
    <div>
      {!isAuthenticated && (
        <Button color="secondary" onClick={() => loginWithRedirect({})}>
          Log in
        </Button>
      )}
      {isAuthenticated && <UserProfile />}
    </div>
  );
};

export default UserNavBar;
