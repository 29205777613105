import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './modules';
import rootSaga from './modules/rootSaga';

import persistMiddleware, {
  loadState,
} from './middleware/persistLocalStorageMiddleware';

// eslint-disable-next-line no-underscore-dangle
const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();

const initialState = loadState('state');
const store = createStore(
  rootReducer,
  initialState,
  storeEnhancers(applyMiddleware(sagaMiddleware, persistMiddleware)),
);

sagaMiddleware.run(rootSaga);

export default store;
