import types from './types';

const requestProfileBegin = () => {
  return {
    type: types.REQUEST_PROFILE_BEGIN,
  };
};

const requestProfileSuccess = payload => {
  return {
    type: types.REQUEST_PROFILE_SUCCESS,
    payload,
  };
};

const requestProfileError = error => {
  return {
    type: types.REQUEST_PROFILE_ERROR,
    error,
  };
};

const setApiToken = token => {
  return {
    type: types.SET_API_TOKEN,
    token,
  };
};

const setLoggedIn = isLoggedIn => {
  return {
    type: types.SET_LOGGED_IN,
    isLoggedIn,
  };
};

const saveProfileBegin = alias => {
  return {
    type: types.SAVE_PROFILE_BEGIN,
    alias,
  };
};

const saveProfileSuccess = payload => {
  return {
    type: types.SAVE_PROFILE_SUCCESS,
    payload,
  };
};

const saveProfileError = error => {
  return {
    type: types.SAVE_PROFILE_ERROR,
    error,
  };
};

export default {
  requestProfileBegin,
  requestProfileSuccess,
  requestProfileError,
  setApiToken,
  setLoggedIn,
  saveProfileBegin,
  saveProfileSuccess,
  saveProfileError,
};
