import types from './types';

const requestFloorsBegin = () => {
  return {
    type: types.REQUEST_FLOORS_BEGIN,
  };
};

const requestFloorsSuccess = payload => {
  return {
    type: types.REQUEST_FLOORS_SUCCESS,
    payload,
  };
};

const requestFloorsError = error => {
  return {
    type: types.REQUEST_FLOORS_ERROR,
    error,
  };
};

export default {
  requestFloorsBegin,
  requestFloorsSuccess,
  requestFloorsError,
};
