import { all, fork } from 'redux-saga/effects';
import { characterSagas } from './entities/characters';
import { floorSagas } from './entities/floors';
import { floorLayoutSagas } from './entities/floorLayouts';
import { authSagas } from './auth';

export default function* rootSaga() {
  yield all([
    fork(characterSagas),
    fork(floorSagas),
    fork(floorLayoutSagas),
    fork(authSagas.watchRequestProfileBegin),
    fork(authSagas.watchSaveProfileBegin),
  ]);
}
