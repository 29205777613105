import { floorSelectors } from '../floors';

const getFloorLayout = state => {
  const currentFloorIndex = state.shadowland.currentFloor;
  const shadowlandFloor = state.shadowland.floor[currentFloorIndex];
  if (shadowlandFloor === undefined) {
    return null;
  }
  return state.entities.floorLayouts.byId[shadowlandFloor.selectedStage];
};

const getFloorBattleTips = state => {
  const floorLayout = getFloorLayout(state);
  if (!floorLayout) {
    return null;
  }

  const floorTip = floorLayout.floorTip ? floorLayout.floorTip : '';
  const floorTypeTip = floorLayout.floorTypeTip ? floorLayout.floorTypeTip : '';
  return `${floorTypeTip} ${floorTip}`;
};

const getFloorMode = state => {
  const floorLayout = getFloorLayout(state);
  if (!floorLayout) {
    return '';
  }

  switch (floorLayout.modeId) {
    case 1:
      return 'Relay';
    case 2:
      return 'Rumble';
    case 3:
      return 'Wave';
    case 4:
      return 'Boss';
    case 5:
      return 'Entry';
    default:
      return '';
  }
};

const getFloorLayoutsByFloor = state => {
  const currentFloorIndex = state.shadowland.currentFloor;
  let layoutIds;
  if (currentFloorIndex >= 31) {
    layoutIds = state.entities.floorLayouts.allIds;
  } else {
    const shadowlandFloor = state.shadowland.floor[currentFloorIndex];
    if (shadowlandFloor === undefined) {
      return {};
    }

    const floors = floorSelectors.getFloorStages(state);
    if (floors === undefined) {
      return {};
    }
    layoutIds = floors.map(floor => floor.floorLayoutId);
  }
  const filtered = state.entities.floorLayouts.allIds
    .filter(key => layoutIds.includes(key))
    .reduce((obj, key) => {
      // eslint-disable-next-line no-param-reassign
      obj[key] = state.entities.floorLayouts.byId[key];
      return obj;
    }, {});
  return filtered;
};

export default {
  getFloorBattleTips,
  getFloorMode,
  getFloorLayoutsByFloor,
};
