import types from './types';
import arrayToObject from '../../../util/stateHelper';
/* State shape
{
  id: {
    "id": number,
    "modeId": number,
    "floorTypeTip": string,
    "floorTip": string,
    "boss": string,
    "mob1": string,
    "mob2": string,
    "mob3": string,
    "mob4": string,
    "mob5": string,
  }.
}
*/

const initialFloorLayoutsState = {
  byId: {},
  allIds: [],
};

const floorLayoutsReducer = (state = initialFloorLayoutsState, action) => {
  switch (action.type) {
    case types.REQUEST_FLOOR_LAYOUTS_SUCCESS: {
      const toInitialize = arrayToObject(action.payload, 'id');
      return {
        ...state,
        byId: toInitialize,
        allIds: action.payload.map(f => f.id),
      };
    }
    case types.REQUEST_FLOOR_LAYOUTS_ERROR:
    default:
      return state;
  }
};

export default floorLayoutsReducer;
