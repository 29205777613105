import types from './types';
import { floorTypes } from '../entities/floors';
import { manageTypes } from '../manage';

/* State shape
{
  currentFloor: int,
  floor: {
    floorId: {
      selectedStage:int,
      notes: string
    }
  }
}
*/

const initialFloor = {
  currentFloor: 1,
  floor: {
    '1': {
      selectedStage: 1,
      notes: '',
    },
  },
  usedCharacters: [],
};

const shadowlandReducer = (state = initialFloor, action) => {
  switch (action.type) {
    case types.NEXT_FLOOR: {
      const nextFloor = state.currentFloor + 1;
      const floor = state.floor[nextFloor] || { selectedStage: null };
      return {
        ...state,
        currentFloor: nextFloor,
        floor: {
          ...state.floor,
          [nextFloor]: {
            ...state.floor[nextFloor],
            ...floor,
          },
        },
      };
    }
    case types.PREV_FLOOR:
      return {
        ...state,
        currentFloor: Math.max(1, state.currentFloor - 1),
      };
    case types.SET_FLOOR:
      return {
        ...state,
        currentFloor: action.floor,
      };
    case types.SET_STAGE:
      return {
        ...state,
        floor: {
          ...state.floor,
          [state.currentFloor]: {
            characters: state.floor[state.currentFloor].characters || [],
            selectedStage: action.stage,
          },
        },
      };
    case types.ASSIGN_CHARACTER: {
      if (state.usedCharacters.indexOf(action.characterId) >= 0) {
        return state;
      }
      return {
        ...state,
        usedCharacters: [...state.usedCharacters, action.characterId],
        floor: {
          ...state.floor,
          [action.floor]: {
            ...state.floor[action.floor],
            characters: [action.characterId].concat(
              state.floor[action.floor].characters || [],
            ),
          },
        },
      };
    }
    case types.UNASSIGN_CHARACTER: {
      if (state.usedCharacters.indexOf(action.characterId) < 0) {
        return state;
      }
      return {
        ...state,
        usedCharacters: state.usedCharacters.filter(
          i => i !== action.characterId,
        ),
        floor: {
          ...state.floor,
          [action.floor]: {
            ...state.floor[action.floor],
            characters: state.floor[action.floor].characters.filter(
              i => i !== action.characterId,
            ),
          },
        },
      };
    }
    case types.SET_NOTES: {
      return {
        ...state,
        floor: {
          ...state.floor,
          [action.floor]: {
            ...state.floor[action.floor],
            notes: action.notes,
          },
        },
      };
    }
    case floorTypes.REQUEST_FLOORS_SUCCESS: {
      const uniqueFloors = [...new Set(action.payload.map(f => f.floor))];
      const initFloors = uniqueFloors.reduce(function remap(map, floor) {
        // eslint-disable-next-line no-param-reassign
        map[floor] = {
          selectedStage: action.payload.find(f => f.floor === floor)
            .floorLayoutId,
        };
        return map;
      }, {});

      return {
        ...state,
        floor: {
          ...state.floor,
          ...initFloors,
        },
      };
    }
    case manageTypes.LOAD_BUILD: {
      return {
        ...state,
        ...action.build,
      };
    }
    default:
      return state;
  }
};

export default shadowlandReducer;
