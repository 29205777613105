import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

const LoadConfirmationAlert = ({ isOpen, handleCancel, handleConfirm }) => {
  function handleClose(hasConfirmed) {
    if (hasConfirmed) {
      handleConfirm();
    } else {
      handleCancel();
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Use saved build?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Loading this build will overwrite current build. Unsaved changes will
          be discarded.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleClose(true)} color="primary" autoFocus>
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
};

LoadConfirmationAlert.defaultProps = {
  isOpen: false,
};

LoadConfirmationAlert.propTypes = {
  isOpen: PropTypes.bool,
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

export default LoadConfirmationAlert;
