import React from 'react';

const ShadowlandIcon = props => {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      {...props}
      fill="#fff"
      width="25px"
      height="25px"
    >
      <g>
        <g>
          <path
            d="M430.616,77.601c-6.78-4.759-16.136-3.123-20.896,3.657l-1.974,2.812l-95.984-51.813c-2.188-1.182-4.638-1.8-7.125-1.8
			h-33.654c-0.198-8.112-6.822-14.63-14.981-14.63c-8.159,0-14.783,6.518-14.981,14.63h-33.654c-2.487,0-4.937,0.619-7.125,1.8
			L104.257,84.07l-1.974-2.812c-4.761-6.78-14.117-8.416-20.896-3.657c-6.78,4.76-8.418,14.116-3.657,20.896l2.31,3.289
			c10.011,14.26,26.39,22.773,43.813,22.773H388.15v0.001c17.424,0,33.803-8.513,43.813-22.773l2.31-3.29
			C439.034,91.717,437.396,82.362,430.616,77.601z"
            fill="fills"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M505.622,227.878c-6.778-4.759-16.135-3.125-20.896,3.656l-2.311,3.291c-3.225,4.594-7.956,7.823-13.267,9.225
			l-83.548-45.1v-44.387H126.403v44.387l-83.548,45.1c-5.311-1.402-10.042-4.632-13.268-9.226l-2.31-3.289
			c-4.76-6.779-14.115-8.416-20.896-3.656c-6.779,4.761-8.416,14.116-3.655,20.896l2.309,3.288
			c10.011,14.26,26.39,22.774,43.814,22.774h414.305v-0.002c17.425,0,33.804-8.514,43.813-22.772l2.31-3.289
			C514.038,241.994,512.401,232.639,505.622,227.878z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M487.579,466.175c-6.194,0-12.295-1.084-18.246-3.217V419.32c0-8.284-6.716-15-15-15h-14.396v-99.483H72.066v99.483
			H57.671c-8.284,0-15,6.716-15,15v43.637c-5.95,2.133-12.051,3.218-18.246,3.218c-8.284,0-15,6.716-15,15s6.716,15,15,15
			c16.152,0,31.681-4.591,46.155-13.645c7.238-4.528,16.231-4.53,23.474-0.001c14.479,9.055,30.01,13.646,46.163,13.646
			c16.151,0,31.681-4.591,46.157-13.645c7.237-4.529,16.232-4.529,23.472-0.001c14.479,9.055,30.01,13.646,46.163,13.646
			c16.152,0,31.682-4.59,46.158-13.642c7.234-4.524,16.226-4.526,23.462-0.001c14.477,9.053,30.006,13.643,46.158,13.643
			c16.151,0,31.681-4.591,46.156-13.645c7.237-4.528,16.231-4.53,23.473-0.001c14.479,9.055,30.01,13.646,46.163,13.646
			c8.284,0,15-6.716,15-15S495.863,466.175,487.579,466.175z M325.263,403.337H188.811v-14.469c0-16.568,13.432-30,30-30h76.452
			c16.568,0,30,13.432,30,30V403.337z"
          />
        </g>
      </g>
    </svg>
  );
};

export default ShadowlandIcon;
