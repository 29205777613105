const SET_FLOOR = 'app/shadowland/SET_FLOOR';
const NEXT_FLOOR = 'app/shadowland/NEXT_FLOOR';
const PREV_FLOOR = 'app/shadowland/PREV_FLOOR';
const SET_STAGE = 'app/shadowland/SET_STAGE';
const ASSIGN_CHARACTER = 'app/shadowland/ASSIGN_CHARACTER';
const UNASSIGN_CHARACTER = 'app/shadowland/UNASSIGN_CHARACTER';
const SET_NOTES = 'app/shadowland/SET_NOTES';

export default {
  SET_FLOOR,
  NEXT_FLOOR,
  PREV_FLOOR,
  SET_STAGE,
  ASSIGN_CHARACTER,
  UNASSIGN_CHARACTER,
  SET_NOTES,
};
