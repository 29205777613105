import { connect } from 'react-redux';
import { authOperations, authSelectors } from '../../modules/auth';
import CallbackPage from './CallbackPage';

const mapStateToProps = state => ({
  isLoggedIn: authSelectors.isLoggedIn(state),
  isProfileLoaded: authSelectors.isProfileLoaded(state),
  hasProfile: authSelectors.hasProfile(state),
});

const mapDispatchToProps = dispatch => ({
  getProfile: () => dispatch(authOperations.requestProfileBegin()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CallbackPage);
