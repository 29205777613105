import React, { useState } from 'react';
import {
  Grid,
  Card,
  Typography,
  CardMedia,
  CardContent,
  CardActions,
  IconButton,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import PropTypes from 'prop-types';
import { useAuth0 } from '../react-auth0-wrapper';
import ProgressIndicator from './ProgressIndicator';

const useStyles = makeStyles(() => ({
  card: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: '20%',
  },
  space: {
    height: '1em',
  },
  rightAligned: {
    marginLeft: 'auto',
  },
}));

const Profile = ({ profile, isProfileLoaded, loadProfile, saveProfile }) => {
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [initAlias, setInitAlias] = useState(false);
  const [values, setValues] = React.useState({
    alias: '',
  });

  const { loading, user } = useAuth0();
  const classes = useStyles();

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSave = () => {
    saveProfile(values.alias);
  };

  if (loading || !user) {
    return <ProgressIndicator />;
  }

  if (!isProfileLoaded && !loadingProfile) {
    loadProfile();
    setLoadingProfile(true);
  }

  if (!isProfileLoaded) {
    return <ProgressIndicator />;
  }

  if (!initAlias) {
    setInitAlias(true);
    setValues({ alias: profile.alias });
  }

  return (
    <Grid item xs={12} md={6}>
      <Card className={classes.card}>
        <CardMedia image={user.picture} className={classes.cover} />
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography variant="h5">{user.name}</Typography>
            <div className={classes.space} />
            <TextField
              id="alias"
              label="Game name"
              value={values.alias}
              onChange={handleChange('alias')}
            />
          </CardContent>
          <CardActions disableSpacing>
            <IconButton className={classes.rightAligned} onClick={handleSave}>
              <SaveIcon />
            </IconButton>
          </CardActions>
        </div>
      </Card>
    </Grid>
  );
};

Profile.propTypes = {
  profile: PropTypes.shape({
    alias: PropTypes.string.isRequired,
  }).isRequired,
  isProfileLoaded: PropTypes.bool.isRequired,
  loadProfile: PropTypes.func.isRequired,
  saveProfile: PropTypes.func.isRequired,
};

export default Profile;
