import React from 'react';
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  TextField,
} from '@material-ui/core';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import RGL, { WidthProvider } from 'react-grid-layout';
import CharacterTile from '../../components/CharacterTile';

const ReactGridLayout = WidthProvider(RGL);

const useStyles = makeStyles(theme => ({
  rightAligned: {
    marginLeft: 'auto',
    display: 'flex',
  },
  avatar: {
    backgroundColor: '#263238',
  },
  tabRoot: {
    minWidth: '5em',
    maxWidth: '5em',
  },
  container: {
    background: theme.palette.primary.main,
  },
}));

const generateLayout = characters => {
  return characters.map((r, index) => {
    return {
      i: `${r}`,
      x: index % 6,
      y: 0,
      w: 1,
      h: 1,
      static: true,
    };
  });
};

const CharacterSelectionCard = ({
  characterSelection,
  notes,
  characterList,
  currentFloor,
  unassignCharacter,
  setNotes,
}) => {
  const classes = useStyles();
  const layout = generateLayout(characterSelection);
  const [values, setValues] = React.useState({
    notes,
  });

  React.useEffect(() => {
    setValues({ notes });
  }, [currentFloor]);

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleBlur = () => {
    setNotes(currentFloor, values.notes);
  };

  return (
    <Card>
      <CardHeader
        title="Characters Selected"
        titleTypographyProps={{ variant: 'h6' }}
        action={<div className={classes.rightAligned} />}
      />
      <CardMedia image="null" className={classes.container}>
        <ReactGridLayout
          layout={layout}
          rowHeight={96}
          containerPadding={[0, 0]}
          margin={[0, 0]}
          cols={6}
        >
          {characterSelection.map(c => {
            return (
              <CharacterTile
                key={`${c}`}
                onClick={() => unassignCharacter(currentFloor, c)}
                imageSource={`/images/${characterList[c - 1].fileName}`}
              />
            );
          })}
        </ReactGridLayout>
      </CardMedia>
      <CardContent>
        <TextField
          name="notes"
          placeholder="Notes"
          fullWidth
          onChange={handleChange('notes')}
          onBlur={handleBlur}
          value={values.notes}
        />
      </CardContent>
    </Card>
  );
};

CharacterSelectionCard.defaultProps = {
  notes: '',
};

CharacterSelectionCard.propTypes = {
  characterSelection: PropTypes.arrayOf(PropTypes.number).isRequired,
  notes: PropTypes.string,
  characterList: PropTypes.arrayOf(
    PropTypes.shape({
      fileName: PropTypes.string,
    }),
  ).isRequired,
  currentFloor: PropTypes.number.isRequired,
  unassignCharacter: PropTypes.func.isRequired,
  setNotes: PropTypes.func.isRequired,
};

export default CharacterSelectionCard;
