import actions from './actions';

const {
  setCharacterLocked,
  setCharacterUnlocked,
  getRoster,
  unlockAllCharacters,
} = actions;

export default {
  setCharacterLocked,
  setCharacterUnlocked,
  getRoster,
  unlockAllCharacters,
};
