import { takeEvery, call, put, select } from 'redux-saga/effects';
import types from './types';
import actions from './actions';
import authSelectors from './selectors';

/* Get Profile */
function getProfile(token) {
  return fetch(process.env.REACT_APP_USERPROFILE_API, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(response => response.json());
}

function* fetchProfile() {
  const token = yield select(authSelectors.getApiToken);
  try {
    const payload = yield call(getProfile, token);
    yield put(actions.requestProfileSuccess(payload));
  } catch (e) {
    yield put(actions.requestProfileError(e));
  }
}

function* watchRequestProfileBegin() {
  yield takeEvery(types.REQUEST_PROFILE_BEGIN, fetchProfile);
}

/* Save Profile */
function postProfile(token, alias) {
  return fetch(process.env.REACT_APP_USERPROFILE_API, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ alias }),
  }).then(response => response.json());
}

function* saveProfile(action) {
  const token = yield select(authSelectors.getApiToken);
  try {
    const payload = yield call(postProfile, token, action.alias);
    yield put(actions.saveProfileSuccess(payload));
  } catch (e) {
    yield put(actions.saveProfileError(e));
  }
}

function* watchSaveProfileBegin() {
  yield takeEvery(types.SAVE_PROFILE_BEGIN, saveProfile);
}

export default {
  watchRequestProfileBegin,
  watchSaveProfileBegin,
};
