import moment from 'moment';

const getBuilds = state => {
  const keys = Object.keys(state.manage.builds);
  const builds = keys.map(k => {
    return {
      ...state.manage.builds[k],
      updatedAtMoment: moment(state.manage.builds[k].updatedAt).fromNow(),
    };
  });
  return builds;
};

export default {
  getBuilds,
};
