import { connect } from 'react-redux';
import AppNavBar from './AppNavBar';
import { drawerOperations } from '../modules/ui/drawer';

const mapStateToProps = state => ({
  isOpen: state.ui.drawer.isOpen,
});

const mapDispatchToProps = dispatch => {
  return {
    openAppDrawer: () => dispatch(drawerOperations.openAppDrawer()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppNavBar);
