import { combineReducers } from 'redux';

import characters from './characters';
import floors from './floors';
import floorLayouts from './floorLayouts';

export default combineReducers({
  characters,
  floors,
  floorLayouts,
});
