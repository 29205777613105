import { connect } from 'react-redux';
import { authSelectors, authOperations } from '../modules/auth';
import Profile from './Profile';

const mapStateToProps = state => ({
  profile: authSelectors.getProfile(state),
  isProfileLoaded: authSelectors.isProfileLoaded(state),
});

const mapDispatchToProps = dispatch => ({
  loadProfile: () => dispatch(authOperations.requestProfileBegin()),
  saveProfile: alias => dispatch(authOperations.saveProfileBegin(alias)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Profile);
