import types from './types';

const requestFloorLayoutsBegin = () => {
  return {
    type: types.REQUEST_FLOOR_LAYOUTS_BEGIN,
  };
};

const requestFloorLayoutsSuccess = payload => {
  return {
    type: types.REQUEST_FLOOR_LAYOUTS_SUCCESS,
    payload,
  };
};

const requestFloorLayoutsError = error => {
  return {
    type: types.REQUEST_FLOOR_LAYOUTS_ERROR,
    error,
  };
};

export default {
  requestFloorLayoutsBegin,
  requestFloorLayoutsSuccess,
  requestFloorLayoutsError,
};
