import React from 'react';
import { Grid } from '@material-ui/core';
import CurrentBuildCardContainer from './CurrentBuildCardContainer';
import BuildsTableContainer from './BuildsTableContainer';

const ManagePage = () => {
  return (
    <React.Fragment>
      <Grid item xs={12} lg={4}>
        <CurrentBuildCardContainer />
      </Grid>
      <Grid item xs={12} lg={8}>
        <BuildsTableContainer />
      </Grid>
    </React.Fragment>
  );
};

export default ManagePage;
