// getStagesByFloor
const getFloorStages = state => {
  if (state.shadowland === undefined) {
    return [];
  }
  const currentFloorIndex = state.shadowland.currentFloor;
  if (state.entities.floors === undefined) {
    return [];
  }
  const stages = state.entities.floors.allIds
    .filter(f => state.entities.floors.byId[f].floor === currentFloorIndex)
    .map(f => state.entities.floors.byId[f]);
  return stages;
};

export default {
  getFloorStages,
};
