import types from './types';

/* State shape
{
  builds: {
      buildId1: {
          id: buildId1,
          name: string,
          description: string,
          build: shadowland,
          createdAt: datetime,
          updatedAt: datetime,
      },
      ...
      buildIdN: {
          ...
      }
  }
}
*/

const initialManageState = {
  builds: {},
};

const manageReducer = (state = initialManageState, action) => {
  switch (action.type) {
    case types.SAVE_BUILD: {
      return {
        ...state,
        builds: {
          ...state.builds,
          [action.id]: {
            id: action.id,
            name: action.name,
            description: action.description,
            build: action.build,
            createdAt: action.createdAt,
            updatedAt: action.updatedAt,
          },
        },
      };
    }
    case types.DELETE_BUILD: {
      const newBuilds = { ...state.builds };
      delete newBuilds[action.id];
      return {
        ...state,
        builds: {
          ...newBuilds,
        },
      };
    }
    default:
      return state;
  }
};

export default manageReducer;
