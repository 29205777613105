import types from './types';

const requestCharactersBegin = () => {
  return {
    type: types.REQUEST_CHARACTERS_BEGIN,
  };
};

const requestCharactersSuccess = payload => {
  return {
    type: types.REQUEST_CHARACTERS_SUCCESS,
    payload,
  };
};

const requestCharactersError = error => {
  return {
    type: types.REQUEST_CHARACTERS_ERROR,
    error,
  };
};

export default {
  requestCharactersBegin,
  requestCharactersSuccess,
  requestCharactersError,
};
