const REQUEST_PROFILE_BEGIN = 'app/auth/REQUEST_PROFILE_BEGIN';
const REQUEST_PROFILE_SUCCESS = 'app/auth/REQUEST_PROFILE_SUCCESS';
const REQUEST_PROFILE_ERROR = 'app/auth/REQUEST_PROFILE_ERROR';
const SET_API_TOKEN = 'app/auth/SET_API_TOKEN';
const SET_LOGGED_IN = 'app/auth/SET_LOGGED_IN';
const SAVE_PROFILE_BEGIN = 'app/auth/SAVE_PROFILE_BEGIN';
const SAVE_PROFILE_SUCCESS = 'app/auth/SAVE_PROFILE_SUCCESS';
const SAVE_PROFILE_ERROR = 'app/auth/SAVE_PROFILE_ERROR';

export default {
  REQUEST_PROFILE_BEGIN,
  REQUEST_PROFILE_SUCCESS,
  REQUEST_PROFILE_ERROR,
  SET_API_TOKEN,
  SET_LOGGED_IN,
  SAVE_PROFILE_BEGIN,
  SAVE_PROFILE_SUCCESS,
  SAVE_PROFILE_ERROR,
};
