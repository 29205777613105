import types from './types';

const setApp = app => {
  return {
    type: types.SET_APP,
    app,
  };
};

export default {
  setApp,
};
