import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, CssBaseline } from '@material-ui/core';
import PropTypes from 'prop-types';
import { BrowserRouter, Route } from 'react-router-dom';
import AppNavBarContainer from '../components/AppNavBarContainer';
import RosterPage from './roster/RosterPage';
import Shadowland from './shadowland/Shadowland';
import ManagePage from './manage/ManagePage';
import ProfileContainer from '../components/ProfileContainer';
import CallbackPageContainer from './auth/CallbackPageContainer';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(4),
  },
}));

const App = ({ currentApp }) => {
  const classes = useStyles();
  return (
    <BrowserRouter>
      <div className={classes.root}>
        <CssBaseline />
        <AppNavBarContainer title="Shadowland Teams" />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Grid container spacing={3}>
            <Route path="/roster" component={RosterPage} />
            <Route path="/shadowland" component={Shadowland} />
            <Route path="/manage" component={ManagePage} />
            <Route path="/profile" component={ProfileContainer} />
            <Route path="/callback" component={CallbackPageContainer} />
          </Grid>
        </main>
      </div>
    </BrowserRouter>
  );
};

App.propTypes = {
  currentApp: PropTypes.string.isRequired,
};

export default App;
