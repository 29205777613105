import React from 'react';
import PropTypes from 'prop-types';
import { GridListTileBar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CharacterTile from '../../components/CharacterTile';
import ReactGridLayout from '../../components/ReactGridLayout';
import CharacterTypeIconButton from './CharacterTypeIconButton';

const gridRowHeights = { lg: 256, md: 96, sm: 96, xs: 64, xxs: 64 };

const useStyles = makeStyles(theme => ({
  tileBar: {
    height: '1.5em',
    lineHeight: '1em',
    bottom: '0px',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',

    justifyContent: 'space-around',
    backgroundColor: theme.palette.background.paper,
  },
  roster: {
    height: '70vh',
    overflowY: 'scroll',
    overflowX: 'hidden',
    background: 'rgba(30,40,45,1)',
  },
}));

const generateCell = (x, y, character) => {
  return {
    i: `${character.id}`,
    x,
    y,
    w: 1,
    h: 1,
    static: true,
    image: character.fileName,
    name: character.name,
    characterType: character.type,
  };
};

const generateLayout = character => {
  return {
    lg: character.map((c, index) => {
      return generateCell(index % 8, Math.floor(index / 8), c);
    }),
    md: character.map((c, index) => {
      return generateCell(index % 7, Math.floor(index / 7), c);
    }),
    sm: character.map((c, index) => {
      return generateCell(index % 3, Math.floor(index / 3), c);
    }),
    xs: character.map((c, index) => {
      return generateCell(index % 2, Math.floor(index / 2), c);
    }),
  };
};

const CharacterList = ({
  characters,
  roster,
  setCharacterUnlocked,
  setCharacterLocked,
}) => {
  const classes = useStyles();
  const gridLayout = generateLayout(characters);
  const [state, setState] = React.useState({ rowHeight: 192 });
  return (
    <div className={classes.roster}>
      <ReactGridLayout
        layouts={gridLayout}
        breakpoints={{ lg: 800, md: 650, sm: 450, xs: 200, xxs: 0 }}
        cols={{ lg: 8, md: 7, sm: 3, xs: 2, xxs: 1 }}
        containerPadding={[0, 0]}
        margin={[0, 0]}
        onBreakpointChange={breakpoint => {
          setState({ ...state, rowHeight: gridRowHeights[breakpoint] });
        }}
        rowHeight={state.rowHeight}
      >
        {gridLayout.lg.map(tileInfo => {
          return (
            <CharacterTile
              key={tileInfo.i}
              imageSource={`/images/${tileInfo.image}`}
              locked={!roster[tileInfo.i] || !roster[tileInfo.i].owned}
              onClick={() =>
                roster[tileInfo.i] && roster[tileInfo.i].owned
                  ? setCharacterLocked(tileInfo.i)
                  : setCharacterUnlocked(tileInfo.i)
              }
            >
              <GridListTileBar
                title={tileInfo.name}
                className={classes.tileBar}
                actionIcon={
                  <CharacterTypeIconButton
                    characterType={tileInfo.characterType}
                    altText={tileInfo.name}
                  />
                }
                actionPosition="left"
              />
            </CharacterTile>
          );
        })}
      </ReactGridLayout>
    </div>
  );
};

CharacterList.defaultProps = {
  floorLayout: null,
};

CharacterList.propTypes = {
  floorLayout: PropTypes.shape({
    id: PropTypes.number,
    modeId: PropTypes.number,
    floorTypeTip: PropTypes.string,
    floorTip: PropTypes.string,
    boss: PropTypes.string,
    mob1: PropTypes.string,
    mob2: PropTypes.string,
    mob3: PropTypes.string,
    mob4: PropTypes.string,
    mob5: PropTypes.string,
  }),
};

export default CharacterList;
