const REQUEST_CHARACTERS_BEGIN =
  'app/entities/characters/REQUEST_CHARACTERS_BEGIN';
const REQUEST_CHARACTERS_SUCCESS =
  'app/entities/characters/REQUEST_CHARACTERS_SUCCESS';
const REQUEST_CHARACTERS_ERROR =
  'app/entities/characters/REQUEST_CHARACTERS_ERROR';

export default {
  REQUEST_CHARACTERS_BEGIN,
  REQUEST_CHARACTERS_SUCCESS,
  REQUEST_CHARACTERS_ERROR,
};
