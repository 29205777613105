const REQUEST_FLOOR_LAYOUTS_BEGIN =
  'app/entities/floorLayouts/REQUEST_FLOOR_LAYOUTS_BEGIN';
const REQUEST_FLOOR_LAYOUTS_SUCCESS =
  'app/entities/floorLayouts/REQUEST_FLOOR_LAYOUTS_SUCCESS';
const REQUEST_FLOOR_LAYOUTS_ERROR =
  'app/entities/floorLayouts/REQUEST_FLOOR_LAYOUTS_ERROR';

export default {
  REQUEST_FLOOR_LAYOUTS_BEGIN,
  REQUEST_FLOOR_LAYOUTS_SUCCESS,
  REQUEST_FLOOR_LAYOUTS_ERROR,
};
