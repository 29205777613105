const SET_CHARACTER_UNLOCKED = 'app/roster/SET_CHARACTER_UNLOCKED';
const SET_CHARACTER_LOCKED = 'app/roster/SET_CHARACTER_LOCKED';
const GET_ROSTER = 'app/roster/GET_ROSTER';
const SET_TIER = 'app/roster/SET_TIER';
const UNLOCK_ALL = 'app/roster/UNLOCK_ALL';

export default {
  SET_CHARACTER_LOCKED,
  SET_CHARACTER_UNLOCKED,
  GET_ROSTER,
  SET_TIER,
  UNLOCK_ALL,
};
