import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  container: {
    border: '1px solid #2b324a',
    textAlign: 'center',
    overflow: 'hidden',
    background: `radial-gradient(farthest-side at 50% 20%, ${
      theme.palette.primary.light
    } 0%, ${theme.palette.primary.main} 100%)`,
  },
  character: {
    height: 'inherit',
    margin: '0 -100%',
    cursor: 'pointer',
  },
  lockedCharacter: {
    '-webkit-filter': 'grayscale(100%); /* Safari 6.0 - 9.0 */',
    filter: 'grayscale(100%);',
  },
}));

const CharacterTile = props => {
  const classes = useStyles();
  const { imageSource, locked, children, ...layoutProps } = props;
  return (
    <div {...layoutProps} className={classes.container}>
      <img
        src={imageSource}
        alt=""
        className={clsx({
          [classes.character]: true,
          [classes.lockedCharacter]: locked,
        })}
      />
      {children}
    </div>
  );
};

CharacterTile.defaultProps = {
  children: null,
  locked: false,
};

CharacterTile.propTypes = {
  imageSource: PropTypes.string.isRequired,
  children: PropTypes.node,
  locked: PropTypes.bool,
};

export default CharacterTile;
