import types from './types';
import { characterTypes } from '../entities/characters';

/* State shape
{
  owned: bool
  tier: int
}
*/

const initialRosterState = {};

const rosterReducer = (state = initialRosterState, action) => {
  switch (action.type) {
    case types.GET_ROSTER:
      return {};
    case types.SET_CHARACTER_LOCKED: {
      const characterState = state[action.characterId];
      return {
        ...state,
        [action.characterId]: {
          ...characterState,
          owned: false,
        },
      };
    }
    case types.SET_CHARACTER_UNLOCKED: {
      const characterState = state[action.characterId];
      return {
        ...state,
        [action.characterId]: {
          ...characterState,
          owned: true,
        },
      };
    }
    case types.UNLOCK_ALL: {
      const characters = Object.keys(state).reduce((map, k) => {
        // eslint-disable-next-line no-param-reassign
        map[k] = {
          ...state[k],
          owned: true,
        };
        return map;
      }, {});
      return {
        ...state,
        ...characters,
      };
    }
    case types.SET_TIER: {
      const characterState = state[action.characterId];
      return {
        ...state,
        [action.characterId]: {
          ...characterState,
          tier: action.tier,
        },
      };
    }
    case characterTypes.REQUEST_CHARACTERS_SUCCESS: {
      const toInitialize = action.payload
        .filter(c => !(c.id in state))
        .reduce(function remap(map, character) {
          // eslint-disable-next-line no-param-reassign
          map[character.id] = {
            owned: false,
            tier: character.isNativeTierTwo ? 2 : 1,
          };
          return map;
        }, {});
      return {
        ...state,
        ...toInitialize,
      };
    }
    default:
      return state;
  }
};

export default rosterReducer;
