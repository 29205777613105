import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  TextField,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '-2em',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 350,
  },
}));

const CurrentBuildCard = ({ currentBuild, saveBuild }) => {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    name: '',
    description: '',
  });

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSave = () => {
    saveBuild(values.name, values.description, currentBuild);
  };

  return (
    <Card>
      <CardHeader
        title="Current Build"
        titleTypographyProps={{ variant: 'h6' }}
        action={
          <Tooltip title="Save">
            <IconButton onClick={() => handleSave()}>
              <SaveIcon />
            </IconButton>
          </Tooltip>
        }
      />
      <CardContent>
        <form noValidate autoComplete="off" className={classes.container}>
          <TextField
            id="name"
            label="Name"
            margin="normal"
            className={classes.textField}
            onChange={handleChange('name')}
            value={values.name}
          />
          <TextField
            id="description"
            label="Description"
            multiline
            margin="normal"
            fullWidth
            style={{ margin: 8 }}
            onChange={handleChange('description')}
            value={values.description}
          />
        </form>
      </CardContent>
    </Card>
  );
};

CurrentBuildCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  currentBuild: PropTypes.object.isRequired,
  saveBuild: PropTypes.func.isRequired,
};

export default CurrentBuildCard;
