import types from '../modules/roster/types';
import { characterTypes } from '../modules/entities/characters';
import { appTypes } from '../modules/app';
import { manageTypes } from '../modules/manage';

export const loadState = key => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }
    const state = JSON.parse(serializedState);
    state.auth = undefined;
    return state;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (key, state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (e) {
    localStorage.setItem('lastError', JSON.stringify(e));
  }
};

const persistToLocalStorage = store => next => action => {
  const toReturn = next(action);
  switch (action.type) {
    case types.SET_CHARACTER_LOCKED:
    case types.SET_CHARACTER_UNLOCKED:
    case types.SET_TIER:
    case characterTypes.REQUEST_CHARACTERS_SUCCESS:
    case appTypes.SET_APP:
    case manageTypes.SAVE_BUILD:
    case manageTypes.DELETE_BUILD:
      localStorage.setItem('state', JSON.stringify(store.getState()));
      break;
    default:
      break;
  }

  return toReturn;
};

export default persistToLocalStorage;
