import { takeEvery, call, put } from 'redux-saga/effects';
import actions from './actions';
import types from './types';

function getData() {
  return fetch(process.env.REACT_APP_CHARACTER_API).then(response =>
    response.json(),
  );
}

function* fetchCharacters() {
  try {
    const payload = yield call(getData);
    yield put(actions.requestCharactersSuccess(payload));
  } catch (e) {
    yield put(actions.requestCharactersError(e));
  }
}

function* watchRequestCharactersBegin() {
  yield takeEvery(types.REQUEST_CHARACTERS_BEGIN, fetchCharacters);
}

export default watchRequestCharactersBegin;
