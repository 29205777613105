import { connect } from 'react-redux';
import CharacterSelectionCard from './CharacterSelectionCard';
import {
  shadowlandOperations,
  shadowlandSelectors,
} from '../../modules/shadowland';
import { characterSelectors } from '../../modules/entities/characters';

const mapStateToProps = state => ({
  characterSelection: shadowlandSelectors.getCharacterSelection(state),
  notes: shadowlandSelectors.getFloorNotes(state),
  characterList: characterSelectors.characterListSelector(state),
  currentFloor: state.shadowland.currentFloor,
});

const mapDispatchToProps = dispatch => ({
  unassignCharacter: (stage, characterId) =>
    dispatch(shadowlandOperations.unassignCharacter(stage, characterId)),
  setNotes: (floor, notes) =>
    dispatch(shadowlandOperations.setNotes(floor, notes)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CharacterSelectionCard);
