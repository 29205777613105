import types from './types';

const setFloor = floor => {
  return { type: types.SET_FLOOR, floor };
};

const nextFloor = () => {
  return { type: types.NEXT_FLOOR };
};

const prevFloor = () => {
  return { type: types.PREV_FLOOR };
};

const setStage = stage => {
  return { type: types.SET_STAGE, stage };
};

const assignCharacter = (floor, characterId) => {
  return { type: types.ASSIGN_CHARACTER, floor, characterId };
};

const unassignCharacter = (floor, characterId) => {
  return { type: types.UNASSIGN_CHARACTER, floor, characterId };
};

const setNotes = (floor, notes) => {
  return { type: types.SET_NOTES, floor, notes };
};

export default {
  setFloor,
  nextFloor,
  prevFloor,
  setStage,
  assignCharacter,
  unassignCharacter,
  setNotes,
};
