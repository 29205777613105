import { connect } from 'react-redux';
import FloorCard from './FloorCard';
import { shadowlandOperations } from '../../modules/shadowland';
import { floorLayoutSelectors } from '../../modules/entities/floorLayouts';

const mapStateToProps = state => ({
  currentFloor: state.shadowland.currentFloor,
  selected: state.shadowland.floor[state.shadowland.currentFloor],
  floorLayouts: floorLayoutSelectors.getFloorLayoutsByFloor(state),
  stageMode: floorLayoutSelectors.getFloorMode(state),
  battleTip: floorLayoutSelectors.getFloorBattleTips(state),
});

const mapDispatchToProps = dispatch => ({
  nextFloor: () => dispatch(shadowlandOperations.nextFloor()),
  prevFloor: () => dispatch(shadowlandOperations.prevFloor()),
  setStage: stage => dispatch(shadowlandOperations.setStage(stage)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FloorCard);
