import React from 'react';
import {
  Tab,
  Card,
  CardHeader,
  CardActions,
  IconButton,
  Avatar,
  CardMedia,
} from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import StyledTabs from '../../components/StyledTabs';
import StageEnemiesGridLayout from './StageEnemiesGridLayout';

const useStyles = makeStyles(theme => ({
  rightAligned: {
    marginLeft: 'auto',
    display: 'flex',
  },
  avatar: {
    backgroundColor: '#263238',
  },
  container: {
    background: theme.palette.primary.main,
  },
  tabRoot: {
    minWidth: '5em',
    maxWidth: '5em',
  },
  card: {
    width: '100%',
  },
}));

const FloorCard = ({
  currentFloor,
  selected,
  floorLayouts,
  stageMode,
  battleTip,
  nextFloor,
  prevFloor,
  setStage,
}) => {
  const classes = useStyles();

  return (
    <Card>
      <CardHeader
        subheader={battleTip}
        title={`Floor ${currentFloor} - ${stageMode}`}
        titleTypographyProps={{ variant: 'h6' }}
        action={
          <div className={classes.rightAligned}>
            <IconButton onClick={prevFloor}>
              <ChevronLeft />
            </IconButton>
            <IconButton onClick={nextFloor}>
              <ChevronRight />
            </IconButton>
          </div>
        }
      />
      <CardMedia
        title="Stage Avatars"
        image="null"
        className={classes.container}
      >
        <StageEnemiesGridLayout
          floorLayout={floorLayouts[selected.selectedStage]}
        />
      </CardMedia>
      <CardActions disableSpacing>
        <StyledTabs
          value={selected.selectedStage}
          scrollButtons="auto"
          variant="scrollable"
        >
          {Object.keys(floorLayouts).map(layoutIdString => {
            const layoutId = parseInt(layoutIdString, 10);
            return (
              <Tab
                key={layoutId}
                value={layoutId}
                onClick={() => setStage(layoutId)}
                disableRipple
                classes={{ root: classes.tabRoot }}
                icon={
                  <Avatar
                    className={classes.avatar}
                    src={`/images/${floorLayouts[layoutId].boss}`}
                  />
                }
              />
            );
          })}
        </StyledTabs>
      </CardActions>
    </Card>
  );
};

FloorCard.defaultProps = {
  stageMode: '',
  battleTip: '',
  floorLayouts: {},
  selected: {},
};

FloorCard.propTypes = {
  currentFloor: PropTypes.number.isRequired,
  selected: PropTypes.shape({
    selectedStage: PropTypes.number,
  }),
  floorLayouts: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number,
      modeId: PropTypes.number,
      floorTypeTip: PropTypes.string,
      floorTip: PropTypes.string,
      boss: PropTypes.string,
      mob1: PropTypes.string,
      mob2: PropTypes.string,
      mob3: PropTypes.string,
      mob4: PropTypes.string,
      mob5: PropTypes.string,
    }),
  ),
  stageMode: PropTypes.string,
  battleTip: PropTypes.string,
  nextFloor: PropTypes.func.isRequired,
  prevFloor: PropTypes.func.isRequired,
  setStage: PropTypes.func.isRequired,
};

export default FloorCard;
