import types from './types';
import arrayToObject from '../../../util/stateHelper';
/* State shape
{
  id: {
    id: int,
    name: string,
    type: int,
    alignment: int,
    gender: int,
    allies: int,
    maxTier: int,
    isNativeTierTwo: bool,
    fileName: string,
    leadership: string,
    created_at: string,
    updated+at: string,
  }
}
*/

const initialCharacterState = {
  byId: {},
  allIds: [],
};

const charactersReducer = (state = initialCharacterState, action) => {
  switch (action.type) {
    case types.REQUEST_CHARACTERS_SUCCESS: {
      const toInitialize = arrayToObject(action.payload, 'id');
      return {
        ...state,
        byId: toInitialize,
        allIds: action.payload.map(c => c.id),
      };
    }
    case types.REQUEST_CHARACTERS_ERROR:
    default:
      return state;
  }
};

export default charactersReducer;
